<template>
	<div class="detail">
		<div class="master-info">
			<!-- <img class="master-image" :src="detailData.avatar" alt=""> -->
			<div class="master-image" style="background-color: #FFFFFF;" >
				<img  :src="detailData.avatar" alt="">
			</div>
			<!-- <div class="master-image" :style="{background:'url('+detailData.avatar+')no-repeat center/contain'}" ></div> -->
			<div class="master-content">
				<div class="master_name">
					<span>{{detailData.name}}</span>
					<span class="master-views">{{detailData.views}}浏览</span>
				</div>
				<div class="master_name">
					<span class="master-org">{{detailData.master_org_text}}</span>
					<span class="master-time">{{detailData.createtime}}</span>
				</div>
				<div class="master-introduce">
					<p>{{detailData.introduce}}</p>
				</div>
				<div class="master_tags">
					<span class="master_tag" v-for="k in detailData.master_categories">{{k.name}}</span>

				</div>
			</div>
		</div>
		<div class="master-detail">
			<van-divider :style="{ color: '#DAA520', borderColor: '#DAA520', padding: '0 16px' ,marginTop:'60px'}">
				传承人详情
			</van-divider>
			<p v-html="detailData.detail"></p>
		</div>

		<div class="master-certImages" v-if="detailData.cert_status == 0">
			<van-divider :style="{ color: '#DAA520', borderColor: '#DAA520', padding: '0 16px' }">
				证书
			</van-divider>
			<img :src="i" alt="" v-for="i in detailData.cert_images">
		</div>
		<div class="master-bottom">
			<div class="mobile">
				<a :href="'tel:' + detailData.mobile" class="mobile-btn"><span>电话咨询</span></a>
				
			</div>
		</div>
	</div>
</template>
<script>
	import * as detail from '@/api/detail'
	import {
		reactive
	} from 'vue';

	import {
		toUrl
	} from '@/utils/tools'
	import {
		Dialog,
		Toast
	} from 'vant';
	export default {

		data() {
			return {
				detailData: '',
				avatar:''
			}
		},
		mounted() {
			this.getDetail()


		},
		methods: {


			//获取详情
			getDetail() {

				detail.getDetail({
					id: this.$route.query.id
				}).then((res) => {
					console.log(res)
					this.detailData = res.data
					this.avatar = res.data.avatar
				})
			},

		}
	}
</script>
<style lang="less" scoped>
	.detail {
		padding: 10px 15px;
padding-bottom: 60px;
		box-sizing: border-box;
		background: linear-gradient(180deg, #DAA520 139px, #f5f5f5 339px);

		.master-bottom {
			height: 60px;
			background-color: #FFFFFF;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 0;
			box-sizing: border-box;
			left: 0;

			.mobile {
				width: 95%;

				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #DAA520;
				padding: 10px 15px;
				border-radius: 10px;
				box-sizing: border-box;

				span {
					font-size: 14px;
					color: #FFFFFF;
				}
			}
		}


		.master-certImages {
			img {
				width: 100%;
				display: block;
				margin: 0;
			}
		}

		.master-info {
			width: 100%;
			background-color: #FFFFFF;
			position: relative;
			top: 50px;
			border-radius: 10px;
			padding: 15px;
			box-sizing: border-box;

			.master-image {
				
				width: 80px;
				height: 80px;
				border-radius: 50%;
				border: 5px solid #FFFFFF;
				position: absolute;
				top: -40px;
				right: 20px;
				z-index: 999;
				img{
					width: 100%;
					border-radius: 50%;
					height: 100%;
					object-fit: cover;
				}
			}

			.master-content {
				.master-introduce {
					p {
						color: #666666;
						font-size: 14px;
					}
				}

				.master_name {
					display: flex;
					margin-bottom: 10px;
					align-items: center;

					.master-org {
						font-size: 14px;
					}

					.master-time,
					.master-views {
						font-size: 12px;
						color: #999999;
						margin-left: 20px;
					}
				}

				.master_tags {
					display: flex;
					flex-wrap: wrap;

					.master_tag {
						margin-right: 5px;
						padding: 2px 5px;
						background: rgba(218, 165, 32, .1);
						color: #DAA520;
						font-size: 10px;
						line-height: 11px;
						margin-top: 11px;
						border-radius: 8px;
						border: 1px solid rgba(218, 165, 32, .1);

					}
				}
			}
		}

	}
</style>
