import request from '@/utils/request'

//获取详情
export function getDetail(params) {
    return request({
        url: '/master/detail',
        method: 'post',
        isLogin: false,
		params
    })
}
